"use client"

import HeaderLayout2 from "@/components/layout/header-layout-2";
import Footer from "@/components/layout/footer-layout-2";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

export default function NotFound() {
  return (
    <>
      <HeaderLayout2 />
      <main className="relative">
        <section className="mt-[4.5rem] bg-muted py-32 dark:bg-slate-900 lg:mt-[161px]">
          <div className="container text-center">
          <DotLottieReact
          src="/lottie/Page-Not-Found.json"
          loop
          autoplay
          />
            {/* <Image
              src="/404.png"
              width={340}
              height={340}
              alt="not found"
              className="mb-12 inline-block"
            /> */}
            <h1 className="mb-4">Sorry We {`Can't`} Find That Page!</h1>
            <p className="mb-12">
              The page you are looking for was moved, removed, renamed or never
              existed.
            </p>
            <Button size="lg" asChild>
              <a href="/">Take me Home</a>
            </Button>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}